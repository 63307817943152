p {
  color: inherit;
  font-size: @font-size-base;
  @media (max-width: @screen-xs-min) {
    font-size: @font-size-base-mobile;
  }
}

a {
  color: @brand-primary;
  .transition (all 0.4s ease-in-out);
  &:hover {
    text-decoration: none;
    color: lighten(@brand-primary, 15%);
  }
  &:focus {
    text-decoration: none;
  }
}

a:active {
  outline: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
input[type="button"],
textarea,
select {
  appearance: none; /* for mobile safari */
  -webkit-appearance: none;
}

// Text Stuff
.text-super-huge {
  font-size: @font-size-super-huge;
  @media (max-width: @screen-sm-min) {
    font-size: @font-size-huge-mobile;
  }
}
.text-huge {
  font-size: @font-size-huge;
  @media (max-width: @screen-sm-min) {
    font-size: @font-size-large-mobile;
  }
}
.text-large {
  font-size: @font-size-large;
  @media (max-width: @screen-sm-min) {
    font-size: @font-size-medium-mobile;
  }
}
.text-medium {
  font-size: @font-size-medium;
  @media (max-width: @screen-sm-min) {
    font-size: @font-size-base-mobile;
  }
}
.text-small {
  font-size: @font-size-small;
}

// Alignment
.text-left           { text-align: left; }
.text-right          { text-align: right; }
.text-center         { text-align: center; }
.text-justify        { text-align: justify; }
.text-nowrap         { white-space: nowrap; }
.text-sub-header     {
  display: inline-block; padding: 10px 20px; font-size: @font-size-medium;
  @media (max-width: @screen-sm-min) {
    font-size: @font-size-medium-mobile;
  }
}
.text-border-bottom  { border-bottom: 1px solid @black; }

// Transformation
.text-lowercase      { text-transform: lowercase; }
.text-uppercase      { text-transform: uppercase; }
.text-capitalize     { text-transform: capitalize; }

// Text Color
.text-white            { color: @brand-white; }
.text-grey            { color: @brand-grey; }
.text-black           { color: @black; }
.text-highlight       { color: @brand-green; }
.text-highlight-pink  { color: @brand-pink; }

// Margins
.make-padding();
.make-margin();
.margin-auto          { margin:auto; }
.no-margin            { margin: 0; }

.text-bold {font-family: '@{font-name-myriad-cond-bold}';}
.font-thirsty {font-family: '@{font-name-thirsty}';}
.font-regular {font-family: '@{font-name-myriad-regular}';}

.popover {
  min-width: 250px;
}

.heading {
  font-weight: bold;
  font-size: @font-size-large;
}

.heading:before {
  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before {
    position: absolute;
    content: "";
    border-top: 3px solid @brand-green;
    width: 50px;
    margin-top: -5px;
    clear: both;
  }
}

.heading:after,
h1:after,
h2:after,
h3:after,
h4:after,
h5:after {
  content: "";
  display: table;
  clear: both;
}

.ignite-collapse {
  padding: 10px 0 10px;
  margin: 0 20px;
  a {
    display:block;
    &:after {
      font-family: 'FontAwesome';
      content: "\f068";
      float: right;
      overflow: no-display;
    }
    &.collapsed {
      &:after {
        content: "\f067";
      }
    }
  }
}

.clickable {
  cursor: pointer;
}