#homepage {
  .carousel-indicators {
    margin-bottom: 0;
    bottom: 0;
  }
  .carousel-caption {
    color: @brand-white;
    top: 10px;
    line-height: 0.9;
    .caption {
      font-size: 36px;
      .bull {
        font-size: 8em;
      }
      .since {
        font-size: 22px;
      }
    }
  }
  .header-section {
    background-color: @brand-light-blue;
    padding: 20px 0;
    h2 {
      font-size: 28px;
      font-weight: normal;
      color: @brand-white;
      margin: 20px 0;
    }

    h2:before,
    h2:after {
      background: @brand-white;
      position: relative;
      vertical-align: middle;
      width: 7.8%;
      height: 2px;
      display: inline-block;
      content: "";
    }

    h2:before {
      right: 0.3em;
    }

    h2:after {
      left: 0.3em;
    }
    span {
      font-size: 26px;
    }
  }
  #offers {
    position: relative;

    #week-offer-border {
      border: 2px dashed @brand-light-blue;
      padding: 10px;
      position: relative;
      #week-offer {
        padding: 15px;
        background-color: @brand-blue;
        h2, h3, h4, p {
          color: @brand-white;
        }
        h2 {
          margin-top: 11px;
          font-size: 44px;
          font-weight: normal;
        }
        h3 {
          margin-top: 22px;
        }
        h4 {
          font-size: 17px;
          font-family: @font-family-regular;
        }
        p {
          font-size: 10px;
          span {
            font-size: 20px;
            margin-right: 4px;
          }
        }
      }
    }
    #buttons-div {
      button {
        border: none;
      }
      #download-button {
        color: @brand-white;
        font-family: @font-family-bold-condensed;
        background-color: @brand-blue;
        padding-left: 40px;
        span {
          margin-right: 15px;
        }
      }
      #other-offers-button {
        color: @brand-white;
        font-family: @font-family-bold-condensed;
        background-color: @brand-light-blue;
      }
    }
  }

  #products {
    #homepage-flow {
      .img-responsive {
        width: 100%;
      }
      .shadow-layer {
        width: 100%;
        height: 100%;
        padding: 25px;
        opacity: 0.6;
        background-color: @brand-homepage-shadow;
        position: absolute;
        top: 0;
      }
      .caption {
        position: absolute;
        top: 20%;
        color: @white;
        width: 100%;
        .title {
          font-size: 42px;
        }
        a {
          padding: 0 10px;
        }
      }
    }
    .header-section,
    &.header-section {
      background-color: #ffffff;
      color: @brand-orange;
      h2 {
        color: @brand-orange;
      }
      h2:before,
      h2:after {
        background: @brand-orange;
      }
      span {
        color: @brand-orange;
      }
    }
  }
  #beach {
    &.header-section {
      background-color: #ffffff;
      color: @brand-beach;
      h2 {
        color: @brand-beach;
      }
      h2:before,
      h2:after {
        background: @brand-beach;
      }
      span {
        color: @brand-beach;
      }
      .btn {
        padding: 5px 15px;
        font-size: 16px;
        .box-border {
          margin: 0;
          padding: 0 12px;
        }
      }
    }
  }
}

@media (min-width: 600px) {
  #homepage {
    .carousel-caption {
      .bull {
        font-size: 5em;
      }
    }
    .header-section {
      min-height: 100px;
      h2 {
        font-size: 54px;
      }
      span {
        font-size: 48px;
      }
      .btn {
        font-size: 22px;
        .box-border {
          margin: 0;
          padding: 5px 50px;
        }
      }
    }
    #products {
      #homepage-flow {
        .caption {
          .pi-4x {
            font-size: 6em;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-ss-min) {
  #homepage {
    .header-section {
      h2 {
        font-size: 58px;
      }
    }
    #offers {
      .hide-picture {
        max-height: 350px;
        min-height: 340px;
        position: relative;
      }
      #week-offer-container {
        position: absolute;
        width: 90%;
        top: 15%;
        left: 5%;

        #week-offer-border {
          max-width: 520px;
          #week-offer {
            h4 {
              margin-top: 0;
            }
            p {
              font-size: 12px;
            }
          }
        }
        #buttons-div {
          margin-top: 10px;
        }
      }
    }

    #products {
      #homepage-flow {
        .caption {
          .title {
            font-size: 52px;
          }
          a {
            font-size: 30px;
          }
        }
      }
    }
  }

}

@media (min-width: @screen-sm-min) {
  #homepage {
    .carousel-caption {
      .bull {
        font-size: 5em;
      }
      .caption {
        font-size: 46px;
      }
    }

    #offers {
      .hide-picture {
        max-height: 550px;
      }
      #week-offer-container {
        #buttons-div {
          margin-top: 45px;
        }
      }
    }

    #products {
      #homepage-flow {
        .caption {
          top: 15%;
          .title {
            font-size: 42px;
          }
          a {
            font-size: 20px;
          }
        }
      }
    }
    #beach {
      &.header-section {
        .btn {
          font-size: 24px;
          padding: 5px 40px;
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  #homepage {
    .carousel-caption {
      top: 0;
      .bull {
        font-size: 9em;
      }
      .caption {
        font-size: 56px;
      }
    }

    #products {
      #homepage-flow {
        .caption {
          top: 20%;
        }
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  #homepage {
    .carousel-caption {
      top: 40px;
      .bull {
        font-size: 11em;
      }
      .caption {
        font-size: 66px;
      }
    }

    img {
      min-width: 100%;
    }

    #products {
      #homepage-flow {
        .caption {
          top: 30%;
          .title {
            font-size: 56px;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-hd-min) {
  #homepage {
    .carousel-caption {
      top: 80px;
    }
    img {
      min-width: 100%;
    }
    #products {
      #homepage-flow {
        .caption {
          .pi-4x {
            font-size: 8em;
          }
        }
      }
    }
  }
}
