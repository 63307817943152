#product-details-main {
  h1, h2 {
    font-size: 30px;
  }

  .navbar {
    margin-bottom: 0;
    &.navbar-default .navbar-nav > li > a:hover,
    &.navbar-default .navbar-nav > li > a:focus {
      color: @brand-orange;
    }
  }

  #product-info {
    padding: 15px;
    .price {
      font-family: @font-family-bold-condensed;
      font-size: 20px;
      color: @brand-grey-text;
    }
    span {
      margin-right: 10px;
      color: @brand-light-blue;
    }
  }
  #product-photo {
    padding: 15px;
    .carousel-control.right,
    .carousel-control.left {
      background: transparent;
    }
  }
  #nutritional-values {
    padding: 15px;
    h2 {
      color: @brand-blue;
    }
    #nutritional-values-table {
      p {
        color: @brand-light-blue;
        font-family: @font-family-bold-condensed;
        margin: 0;
      }
      .percentage {
        color: @brand-blue;
        font-size: 25px;
        &.mg-bottom-0 {
          margin-bottom: 0;
        }
      }

      .value {
        font-size: 27px;
      }
      hr {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .name {
        font-size: 18px;
      }
    }
    #post-scriptum {
      p {
        font-size: 12px;
        margin-top: 30px;
      }
    }
  }

  #allergens {
    padding: 15px;
    background-color: @brand-orange;
    h2 {
      color: @brand-white;
    }
    #no-allergens {
      h2 {
        font-size: 50px;
        margin-bottom: 11px;
        span {
          font-size: 90px;
        }
      }
    }
    #allergens-table {
      padding: 0;
      .allergen-info {
        padding: 0;
        p {
          color: @brand-white;
          &.icon {
            font-size: 70px;
          }
          &.name {
            font-family: @font-family-bold-condensed;
            font-size: 18px;
          }
        }

      }
    }
  }
}

@media (min-width: @screen-sm) {
  #product-details-main {
    h1, h2 {
      font-size: 34px;
    }

    #product-info {
      padding: 30px 15px;
    }

    #product-photo {
      padding: 30px 15px;
    }

    #nutritional-values {
      padding: 30px 15px;
      h2 {
        margin-bottom: 50px;
      }
      #nutritional-values-table {
        .percentage {
          margin-bottom: 20px;
          &.mg-bottom-0 {
            margin-bottom: 0;
          }
        }
      }
      #post-scriptum {
        p {
          font-size: 14px;
        }
      }
    }

    #allergens {
      padding: 50px 15px;
      h2 {
        margin-bottom: 70px;
      }
      #no-allergens {
        h2 {
          font-size: 70px;
          span {
            font-size: 120px;
          }
        }
      }
      #allergens-table {
        .allergen-info {
          p {
            &.icon {
              font-size: 90px;
            }
            &.name {
              font-size: 20px;
              margin-top: 30px;
            }
          }

        }
      }
    }

  }
}
