#blog-filters {
  font-size: 14px;
  margin-top: 20px;
  ul.menu-blog {
    margin: 15px 0;
    padding-left: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 5px;
    }
  }
  h4 {
    margin-bottom: 0;
  }
  .input-group-addon {
    padding: 0;
    button {
      padding: 5px 20px;
    }
  }
}

#blog {
  #blog-details-page {
    margin-top: 10px;
  }

  h1 {
    margin-bottom: 0;
    color: @brand-blue;
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    color: @brand-light-blue;
  }

  h4 {
    font-size: 24px;
  }
  .title {
    margin-bottom: 5px;
  }
  .sub-title {
    margin-top: 5px;
  }

  .background-image {
    max-height: 475px;
    overflow: hidden;
    .img-responsive {
      width: 100%;
    }
  }

  .input-group-addon {
    padding: 0;
    button {
      padding: 5px 20px;
    }
  }

  .social-icon {
    font-size: 20px;
    margin-right: 10px;
    color: @brand-grey;
  }

  .blog-entry {
    border-bottom: 1px solid #A6B7BC;
    padding: 20px 0;

    &:last-child {
      border-bottom: 0;
    }

    h2 {
      font-size: 24px;
      color: @brand-blue;
      margin-bottom: 0;
    }

    .content {
      margin: 15px 0;
    }

    .btn {
      font-size: 14px;
    }
  }
}