/* ======= Header ======= */
.header {
  background: #fff;
  z-index: 888;
  .container {
    width: auto;
    @media (min-width: @screen-lg-min) {
      width: @container-lg;
    }
  }

  .logo {
    img {
      max-width: 104px;
      margin: auto;
    }
  }

  @media (min-width: 1600px) {
    .logo {
      img {
        display: block;
      }
    }
  }
  .locale-switch {
    span {
      padding: 5px;

      &.last {
        margin-right: 0;
        padding-right: 0;
      }
    }
    a {
      text-decoration: none;
      color: #276a7e;
    }
    .active {
      a {
        text-decoration: none;
        color: @brand-blue;
      }
    }
  }

  .main-nav {
    .navbar-toggle {
      margin-right: 0;
      background: darken(@brand-light-blue, 10%);
      color: #fff !important;
      .border-radius(4px);
      &:focus {
        outline: none;
      }
      &:hover {
        background: @brand-light-blue;

      }
      .icon-bar {
        background-color: #fff;
      }

    }
    #navbar-collapse {
      @media (min-width: @screen-sm) {
        padding: 0;
      }
    }

    @media (min-width: @screen-sm) {
      #navbar-collapse {
        display: inline-block !important;
      }
    }

    .nav {
      .nav-item {
        color: @brand-light-blue;
        font-weight: normal;
        text-transform: uppercase;
        font-size: 14px;
        text-align: center;
        padding: 1px 15px;
        width: 50%;
        float: left;
        @media (min-width: @screen-sm-min) {
          margin-right: 10px;
          padding: 0;
          width: inherit;
        }

        a {
          border: 1px solid  @brand-blue;
          @media (min-width: @screen-sm-min) {
            border: none;
            padding: 10px 3px;
          }
          @media (min-width: @screen-sm-max) {
            padding: 10px 15px;
          }
          color: @brand-light-blue;
          font-weight: bold;
          .transition (none);
          &:hover {
            color: @brand-light-blue;
            background: none;
          }
          &:focus {
            outline: none;
            background: none;
          }
          &:active {
            outline: none;
            background: none;
          }
        }

        &.active {
          color: @brand-dark-blue;
        }
        &.last {
          margin-right: 0;
          a {
            margin-right: 0;
            padding-right: 0;
          }
        }
      }

      .open {
        & > a {
          background: none;
          &.btn {
            background: darken(@brand-light-blue, 10%);
          }
        }
      }
      .dropdown-menu {
        .border-radius(4px);
        border: 1px solid @smoky-white;
        border-top: 3px solid @brand-light-blue;
        margin: 0;
        text-transform: none;
        min-width: 180px;

        a {
          border-bottom: 1px dotted @smoky-white;
          padding: 8px 20px 0 0;
          color: @text-color;
          .opacity(1);

          &:hover {
            background: #fff;
            color: @brand-light-blue;
          }
        }

        &:before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid @brand-light-blue;
          position: absolute;
          right: 30px;
          top: -10px;
        }

      }

    }
  }
}
