#voucher_modal {
  background-color: rgba(red(@brand-blue), green(@brand-blue), blue(@brand-blue), 0.8);
  .modal-content {
    border: 2px dashed rgba(21, 57, 68, 0.8);
    .modal-body {
      text-align: center;
      h2, h3 {
        color: @brand-light-blue;
        margin: 0;
      }
    }
  }
}

/* ======= FAQ Section ======= */
#step {
  background-color: @brand-light-blue;
  color: @brand-white;
  #steps {
    min-height: 400px;

    h2 {
      margin: 40px 30px 0 30px;
      color: @brand-white;
    }

    h3 {
      color: @brand-light-blue;
    }

    p {
      font-size: @font-size-medium;
    }
    .display-table {
      .display-cell {
        vertical-align: top;
      }
    }

    .pi {
      margin: 40px auto;
    }
  }
}

#promotions {
  color: @brand-light-blue;
  .header {
    margin: 40px 0;
    h2 {
      margin: 40px 0 0 0;
    }
    h3 {
      margin: 0 0 30px 0;
      color: @brand-light-blue;
      font-size: 24px;
    }
  }

  .carousel-inner {
    height: 460px;
    > .item {
      position: absolute;
      width: 100%;
      .transition(.3s ease-in-out left);

      // WebKit CSS3 transforms for supported devices
      @media all and (transform-3d), (-webkit-transform-3d) {
        .transition-transform(~'0.2s ease-in-out');

        &.next,
        &.active.right {
          .translate3d(66.6%, 0, 0);
          left: 0;
        }

        &.prev,
        &.active.left {
          .translate3d(-33.3%, 0, 0);
          left: 0;
        }

        &.next.left,
        &.prev.right,
        &.active {
          .translate3d(0, 0, 0);
          left: 0;
        }
      }
    }
  }

  .controls {
    margin-top: 15px;
    .carousel-controls {
      width: 80px;
      font-size: @font-size-medium;
      padding: 0 5px;
      background-color: @brand-light-blue;
      .border-radius(25px);
      height: 26px;
      vertical-align: middle;

      a {
        cursor: pointer;
        color: @brand-white;
      }
    }
  }

  .promotion {
    color: @brand-white;
    font-family: @font-name-myriad-cond-bold;
    border: @brand-light-blue dashed 2px;
    height: 460px;
    margin: auto;

    .picture {
      height: 250px;
      margin: auto;
      img {
        max-width: 100%;
      }
    }
    .caption-item {
      height: 206px;
      padding: 10px;
      background-color: @brand-blue;
      h3, h4 {
        color: @brand-white;
      }
      .until-date {
        font-size: @font-name-myriad-regular;
      }

      .download {
        font-size: @font-size-medium;
        font-weight: bold;
        padding-top: 15px;
        text-align: right;
        cursor: pointer;
      }
    }
  }

  hr {
    border-top: 2px solid @brand-light-blue;
  }
}

#partnerships {
  margin-bottom: 20px;
  .header {
    h2 {
      margin: 20px 0 0 0;
    }
    h3 {
      margin: 0 0 30px 0;
      color: @brand-light-blue;
      font-size: 24px;
    }
  }
  .carousel-inner {
    height: 530px;
    >
    .item {
      min-height: 200px;
      .picture {
        min-height: 200px;
        margin: 40px 0;
        img {
          min-width: 100%;
          border: 1px solid @brand-light-blue;
        }
      }
    }
  }

  .controls {
    margin-top: 15px;
    .carousel-controls {
      height: 70px;
      font-size: @font-size-medium;
      padding: 5px 0;
      background-color: @brand-light-blue;
      .border-radius(25px);

      div {
        cursor: pointer;
        color: @brand-white;
      }
    }
  }
  hr {
    border-top: 2px solid @brand-light-blue;
  }
}

#points_campaign {
  h3 {
    color: @brand-light-blue;
    margin: 0 0 40px 0;
  }
  hr {
    border-top: 2px solid @brand-light-blue;
  }
  .attach {
    display: inline-block;
    margin: 30px 10px;
    max-width: 80px;
  }
  .points-campaign-catalog {
    line-height: 1;
    i{
      font-size: 40px;
    }
  }
}

@media (min-width: @screen-ss-min) {
  #voucher_modal {
    .modal-content .modal-body {
      padding: 60px 40px;
    }
  }
}
