#budget-request-page {
  #budget-request-photo{
    .main-photo {
      overflow: hidden;
      padding: 0;
      img {
        width: 100%;
      }
    }
  }

  .placeholder-color(@brand-orange);
  .placeholder-font-family("@font-name-myriad-regular, @font-name-myriad-cond, @font-name-myriad-cond-bold");
  .placeholder-font-size(16px);

  #budget-request-form {
    background-color: @brand-orange;
    h2 {
      color: @brand-white;
      margin-bottom: 40px;
    }
    p {
      color: @brand-white;
      font-size: 20px;
      font-family: @font-name-myriad-cond-bold, @font-name-myriad-cond, @font-name-myriad-regular;
      margin-top: 5px;
    }
    .btn-default {
      color: @white;
      background-color: @brand-light-blue;
    }
    .datetimepicker{
      thead{
        color: @brand-blue;
      }
      tfoot{
        display: none;
      }
    }
    .input-group-addon {
      color: @white;
      background-color: @brand-light-blue;
    }
    .has-error .help-block {
      color: @black;
    }

    #send-message {
      padding-right: 15px;
      button {
        background-color: @white;
        color: @brand-orange;
        border: none;
        font-size: 16px;
        font-family: @font-name-myriad-regular, @font-name-myriad-cond, @font-name-myriad-cond-bold;
        height: 35px;
        width: 280px;
      }
    }
  }

  #food-menus-info {
    margin: 50px 0;
    h3 {
      color: @brand-light-blue;
      margin-bottom: 20px;
    }
    button {
      border: none;
      font-size: 14px;
      font-family: @font-name-myriad-cond-bold, @font-name-myriad-cond, @font-name-myriad-regular;
      width: 100%;
      line-height: 2.5;
    }
  }
}

@media (min-width: @screen-sm) {
  #budget-request-page {
    #budget-request-form {
      .label-right{
        text-align: right;
      }
    }
  }
}
