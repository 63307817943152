body {
  font-family: @font-name-myriad-regular, @font-name-myriad-cond, @font-name-myriad-cond;
  line-height: 1.8;
  color: @text-color;
  font-size: @font-size-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .container {
    @media (max-width: @screen-md-min) {
      width: 100%;
    }
  }

}

html, body {
  height: 100%;
}

h1, .h1 {
  font-size: @font-size-huge;
  color: @brand-light-blue;
}

//  34px
h2, .h2 {
  font-size: @font-size-huge;
  color: @brand-light-blue;
}

//  34px
h3, .h3 {
  font-size: @font-size-large;
  color: @brand-blue;
}

// 24px
h4, .h4 {
  font-size: @font-size-medium;
  color: @brand-blue;
}

//20px
h5, .h5 {
  font-size: ceil(@font-size-base * 1.25);
  color: @brand-blue;
}

// 20px
h6, .h6 {
  font-size: @font-size-base;
  color: @brand-blue;
}

// 16px

p {
  color: @text-color;
  font-size: @font-size-base;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: @light-grey;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: @light-grey;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: @light-grey;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: @light-grey;
}

.wrapper {
  min-height: 70%;
}

/* Align vertically a column */
.display-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  .display-cell {
    display: table-cell;
    vertical-align: middle;
    float: none;
    &.display-cell-bottom {
      vertical-align: bottom;
    }
  }
}

.form-control {
  .box-shadow(none);
  border-color: lighten(@light-grey, 5%);
  &:focus {
    border-color: @brand-light-blue;
    .box-shadow(none);
  }
}

.panel {
  border: 2px solid;
  &.panel-primary {
    border-color: @brand-primary;
  }
}

hr {
  margin-top: 12px;
  margin-bottom: 12px;
}

.popover {
  min-width: 250px;
}

.ignite-collapse {
  padding: 10px 0px 10px;
  margin: 0px 20px;
  a {
    display: block;
    &:after {
      font-family: 'FontAwesome';
      content: "\f068";
      float: right;
      overflow: no-display;
    }
    &.collapsed {
      &:after {
        content: "\f067";
      }
    }
  }
}

ol {
  counter-reset: li;
}

.cms-page {
  margin-top: 30px;
  margin-bottom: 50px;
  .title {
    color: @brand-blue;
    margin-bottom: 0;
  }
  .sub-title {
    color: @brand-light-blue;
    margin-top: 0;
    margin-bottom: 30px;
  }
}

.no-padding {
  padding: 0;
}

.hide-picture {
  overflow: hidden;
}

.jssocials-share {
  display: inline-block;
}

#contact-us-page {
  margin-top: 15px;
}