/* ======= FAQ Section ======= */
#faqs {
  color: #7F7F7F;
  h3 {
    margin: 50px;
    margin-left: auto;
    color: #7F7F7F;
  }

  .faqs {
    width: 100%;
    float: left;

    .box {
      border-bottom: solid 2px black;
      border-color: @brand-grey;
      width: 100%;
      padding-bottom: 10px;
      margin-bottom: 5px;
      cursor: pointer;
    }

    .faq {
      padding: 10px 0;
    }
    &:last-child {
      margin-bottom: 20px;
    }
  }
}
