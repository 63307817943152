#login-page {
  p {
    font-family: @font-family-bold-condensed;
    color: @brand-grey-text;
  }
  button {
    font-family: @font-family-bold-condensed;
  }

  #my-signin2 {
    padding: 0 58px;
  }
}
