#cms-page {
  h1,h2,h3,h4,h5,h6{
    background-color: transparent !important;
  }
  h1 {
    color: @brand-grey-text;
  }
  h2, h3 {
    color: @brand-grey;
  }
  h4 {
    color: @brand-green;
  }
  h5, h6 {
    color: @brand-pink;
  }
  img {
    width: 100%;
    height: auto;
  }
  p{
    background-color: transparent !important;
    text-align: justify;
  }
  .col-xs-24 {
    margin-top: 20px;
  }
  .sub-title{
    color: @brand-grey;
  }
  .attach-description {
    text-align: center;
  }
}
