.box-fill {
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 20px;
  &.disabled {
    opacity: 1;
  }
  &.box-lg {
    line-height: 40px;
  }
  &.box-grey {
    background-color: @brand-grey;
    border-color: @brand-grey;
    color: @brand-white;
  }
  &.box-dark-grey {
    background-color: @brand-dark-grey;
    border-color: @brand-dark-grey;
    color: @brand-white;
  }
  &.box-black {
    background-color: @black;
    border-color: @black;
    color: @brand-white;
  }
  &.box-pink {
    background-color: @brand-pink;
    border-color: @brand-pink;
    color: @brand-white;
  }
  &.box-green {
    background-color: @brand-green;
    border-color: @brand-green;
    color: @brand-white;
  }
  .box-edit {
    position: absolute;
    top: 10px;
    right: 30px;
    font-size: @font-size-small;
  }
}

.box-border {
  margin-bottom: 5px;
  border:solid 2px black;
  &.box-lg {
    line-height: 40px;
  }
  a {
    color: inherit;
  }
  &.green{
    border-color: @brand-green;
  }
  &.beach{
    border-color: @brand-beach;
    &.dashes{
      border:dashed 2px @brand-beach;
      padding: 5px 20px;
    }
  }
  &.pink{
    border-color: @brand-pink;

    .heading:before,
    h1:before,
    h2:before,
    h3:before,
    h4:before,
    h5:before {
      border-top: 3px solid @brand-pink;
    }
  }
  .ignite-collapse {
    margin: 0;
    padding: 10px;
  }
}