.calc-grid-column(@index, @class, @type) when (@type = no-padding) {
  .col-@{class}-no-padding-@{index} {
    padding-left: 0;
    padding-right: 0;
  }
}

.calc-grid-column(@index, @class, @type) when (@class = xs)  and (@type = width) {
  @media (max-width: @screen-xs-min) {
    .col-@{class}-@{index} {
      [class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}

// Basic looping in LESS
.loop-margin(@index) when (@index >= 0) {
  .mg-top-@{index} {margin-top: unit(@index , px); }
  .mg-bottom-@{index} {margin-bottom: unit(@index , px); }
  .mg-right-@{index} {margin-right: unit(@index , px); }
  .mg-left-@{index} {margin-left: unit(@index , px); }
  .mg-@{index} {margin: unit(@index , px); }
  // next iteration
  .loop-margin((@index - 5));
}

// Create grid for specific class
.make-margin() {
  .loop-margin(50);
}

// Basic looping in LESS
.loop-padding(@index) when (@index >= 0) {
  .pd-center-@{index} {padding: 0 unit(@index , px); }
  .pd-middle-@{index} {padding: unit(@index , px) 0; }
  .pd-@{index} {padding: unit(@index , px); }
  // next iteration
  .loop-padding((@index - 5));
}

// Create grid for specific class
.make-padding() {
  .loop-padding(50);
}

// Create grid for specific class
.make-grid(@class) {
  .loop-grid-columns(@grid-columns, @class, no-padding);
}
