/* ======= Registration Section ======= */
#register {
  .panel-body {
    .pro-register {
      margin: 15px;
      text-align: center;
      a {
        font-size: 20px;
      }
      span {
        font-size: 17px;
        strong {
          color: @brand-pink;

        }
      }
    }
    .help-block {
      text-align: right;
      .popover {
        width: 710%;
        max-width: 710%;
      }
    }
    input {
      font-size: 15px;
    }
  }
  #chief_company_registration_company_type {
    font-size: 13px;
  }
  .title {
    margin-top: 60px;
    margin-left: 15px;
    text-align: center;
    font-size: 31px;
  }
  .user-comments {
    .img {
      width: 121px;
      height: 112px;
      margin-bottom: 20px;
    }
  }
}

@media (min-width: @screen-sm-min) {
  #register {
    .title {
      text-align: left;
      font-size: 36px;
    }
    .panel-body {
      .radio-options {
        text-align: center;
        label {
          font-size: 18px;
        }
      }
      .help-block {
        .popover {
          width: 170%;
          max-width: 170%;
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  #register {
    .panel-body {
      .pro-register {
        a {
          font-size: 25px;
        }
        span {
          font-size: 22px;
        }
      }
      .help-block {
        .popover {
          width: 246%;
          max-width: 246%;
        }
      }
    }
  }
}

@media (min-width: @screen-lg-min) {
  #register {
    .panel-body {
      .help-block {
        .popover {
          width: 384%;
          max-width: 384%;
        }
      }
    }
  }
}