/* ======= Footer ======= */
.footer {
  background-color: @brand-dark-blue;
  color: #fff;
  padding: 0 0;

  .footer-col .footer-col-inner {
    .about {
      .more {
        .fa {
          margin-left: 5px;
          position: relative;
          top: 1px;
        }
      }
    }
    .links {
      .fa {
        margin-right: 5px;
      }
      li {
        margin-bottom: 10px;
      }
    }
    .contact {
      .fa {
        margin-right: 10px;
      }
    }

    .title {
      font-weight: normal;
      font-size: 18px;
    }
    p {
      color: @brand-white;
    }
    a {
      color: @brand-white;
      &:hover {
        color: darken(@brand-pink, 10%);
      }
    }
  }
  .footer-content {
    color: @brand-white;
    h1 {
      color: @brand-white;
      font-size: 30px;
      margin-top: 10px;
    }
    .slogan {
      color: @brand-white;
      margin-bottom: 25px;
    }
    h3 {
      color: @brand-white;
      font-size: 18px;
      margin-top: 30px;
      margin-bottom: 20px;
    }
    .stores {
      color: @brand-white;
    }
    .footer-top {
      a {
        img {
          margin-top: 10px;
          margin-bottom: 15px;
        }
      }
    }
    #subscribe-newsletter {
      ::-webkit-input-placeholder {
        color: @brand-grey;
      }
      :-moz-placeholder {
        color: @brand-grey;
      }
      ::-moz-placeholder {
        color: @brand-grey;
      }
      :-ms-input-placeholder {
        color: @brand-grey;
      }
      .input-group {
        input {
          color: @white;
          background-color: @brand-dark-blue;
        }
        .input-group-btn {
          #loading-gif {
            color: @brand-dark-blue;
            font-size: 18px;
          }
        }
      }
    }
    #contact-us {
      p {
        &.stores {
          font-size: 13px;
        }
        color: @white;
        span {
          &.email-span {
            margin-right: 15px;
          }
          &.phone-span {
            margin-right: 19px;
          }
        }
        .livro-reclamacao {
          color: #fff;
          font-size: 14px;
        }
        a {
          color: #fff;
        }
      }
    }
  }

  .bottom-bar {
    background-color: @brand-dark-blue;
    color: lighten(@grey, 10%);
    font-size: 10px;
    padding: 10px 0;
    .copyright {
      display: inline-block;
      vertical-align: bottom;
      margin-top: 13px;
    }
    .call_costs {
      color: #fff;
      font-size: 14px;
    }
  }

    .footer-newsletter {
      ::-webkit-input-placeholder {
        color: @brand-white;
      }
      :-moz-placeholder { /* Firefox 18- */
        color: @brand-white;
      }
      ::-moz-placeholder {  /* Firefox 19+ */
        color: @brand-white;
      }
      :-ms-input-placeholder {
        color: @brand-white;
      }
      color: @brand-white;
      min-height: 100px;
      padding: 30px 0;
      text-align: center;
      h4 {
        color: @brand-white;
      }
      p {
        color: @brand-white;
      }
      .input-group {
        input {
          background: transparent;
          color: @brand-white;
        }
        .btn {
          padding: 6px 20px 6px;
        }
      }
    }
  .homepage {
    .footer-newsletter {
      background-color: @brand-blue;
    }
    .footer-content {
      color: @brand-white;
      text-align: center;
    }
  }
}
