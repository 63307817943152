#food-menus-main {
  .main-photo {
    overflow: hidden;
    padding: 0;
    margin: auto;
    img {
      width: 100%;
    }
  }
  #food-menus-menu {
    background-color: @brand-dark-blue;
    a {
      color: @brand-white;
    }
    .nav-tabs {
      border-bottom: 1px solid @brand-dark-grey;
      .dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0; // remove the gap so it doesn't close
      }
      li {
        padding: 0;
        border: 1px solid @brand-dark-grey;
        a {
          display: block;
          margin: 0;
          font-family: @font-family-bold-condensed;
          font-size: 20px;
          line-height: 0.428571;
        }
        ul {
          margin: 0;
          background-color: @brand-dark-blue;
          width: 100%;
          text-align: center;
          li {
            border: none;
            &.divider {
              margin: 1px 0;
              background-color: @brand-dark-grey;
            }
            a {
              line-height: 1;
            }
          }
        }
      }
      li.active {
        a {
          border-color: transparent;
        }
      }
    }
    span {
      color: @brand-white;
      font-size: 50px;
      margin-top: 20px;
    }
    h1 {
      margin-top: 0;
      padding-bottom: 20px;
      font-family: @font-name-thirsty;
      font-size: 45px;
      color: @brand-white;
    }
  }

  #food-menus-filters {
    margin-top: 50px;
    font-size: 14px;
    .glyphicon {
      top: 0;
    }
    .btn {
      font-family: @font-family-regular;
    }
    form {
      .title-div {
        padding: 0 15px;
      }
      .max-price-div {
        padding-left: 15px;
        padding-right: 10px;
      }
      .filter-button {
        padding-right: 20px;
        font-size: 14px;
      }
      .input-group-addon {
        color: @brand-white;
        background-color: @brand-blue;
      }
    }
  }

  #food-menus-products {
    padding: 0 0 20px;
    .product {
      padding: 0;
      margin-top: 15px;
      border: 1px solid @brand-grey;
      height: 380px;

      .product-photo {
        padding: 0;
        max-height: 330px;
        img {
          margin: auto;
        }
      }
      .product-name {
        p {
          font-family: @headings-font-family;
          color: @brand-white;
        }
      }
      .product-description {
        margin-top: 20px;
        p {
          color: @brand-grey-text;
        }
      }
      .product-footer {
        position: absolute;
        padding: 0 15px;
        bottom: 0;
        span, p {
          color: @brand-grey;
        }
        .fa-facebook {
          margin-right: 10px; // Myriad Pro / Bold Condensed : 14 PX
        }
      }
    }
  }
}

@media (min-width: @screen-ss) {
  #food-menus-main {
    #food-menus-menu {
      h1 {
        font-size: 45px;
      }
    }
    #food-menus-filters {
      form {
        .title-div {
          padding-right: 20px;
          padding-left: 0;
        }
        .max-price-div {
          padding: 0;
        }
      }
    }
    #food-menus-products {
      .product {
        &.product-left {
          margin-right: 8%;
        }
      }
    }
  }
}

@media (min-width: @screen-sm) {
  #food-menus-main {
    #food-menus-filters {
      form {
        .title-div {
          padding: 0;
        }
        .filter-button {
          padding-right: 20px;
        }
      }
    }

    #food-menus-products {
      .product {
        &.product-left {
          margin-right: 0;
        }
        &.product-middle {
          margin-left: 6%;
          margin-right: 6%;
        }
      }
    }
  }
}

@media (min-width: @screen-md) {
  #food-menus-main {
    .main-photo {
      max-width: @container-md;
    }

    #food-menus-menu {
      .nav-tabs {
        li {
          &.col-md-menu {
            width: 10%;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-lg) {
  #food-menus-main {
    #food-menus-menu {
      .nav-tabs {
        li {
          &.col-md-menu {
            width: 8.33%;
          }
        }
      }
    }
  }
}