.breadcrumb {
  padding: 10px 15px;
  margin: auto;
}
/* Subnav */
// Default navbar
.-default {
  .navbar-nav {
    > .current_ancestor > a {
      &,
      &:hover,
      &:focus {
        color: @navbar-default-link-active-color;
        background-color: @navbar-default-link-active-bg;
      }
    }
  }
}
// Default navbar
.navbar-black {
  #gradient > .vertical(@start-color: lighten(@black, 10%); @end-color: @black);
  .reset-filter(); // Remove gradient in IE<10 to fix bug where dropdowns don't get triggered
  border-radius: @navbar-border-radius;
  @shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
  .box-shadow(@shadow);

  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    #gradient > .vertical(@start-color: lighten(@navbar-default-link-active-bg, 25%); @end-color: lighten(@navbar-default-link-active-bg, 30%));
    .box-shadow(inset 0 3px 9px rgba(0,0,0,.075));
  }

  .nav {
    .nav-item {
      &.active {
        a {
          color: darken(@color, 10%);
          font-weight: bold;
        }
      }
    }
  }
}

.subnav {
  width: 100%;
  background-color: @black; /* Old browsers */
  background-repeat: repeat-x; /* Repeat the gradient */
  background-image: -moz-linear-gradient(top, #f5f5f5 0%, @black 100%); /* FF3.6+ */
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f5f5f5), color-stop(100%,@black)); /* Chrome,Safari4+ */
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%,@black 100%); /* Chrome 10+,Safari 5.1+ */
  background-image: -ms-linear-gradient(top, #f5f5f5 0%,@black 100%); /* IE10+ */
  background-image: -o-linear-gradient(top, #f5f5f5 0%,@black 100%); /* Opera 11.10+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f5f5', endColorstr='@black',GradientType=0 ); /* IE6-9 */
  background-image: linear-gradient(top, #f5f5f5 0%,@black 100%); /* W3C */
  border: 1px solid #e5e5e5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}