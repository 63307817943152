#stores-location {
  #title {
    margin-bottom: 30px;
    h2, h3 {
      color: @brand-light-blue;
    }
    h2{
      font-size: 28px;
    }
  }

  #stores {
    margin: 20px 0;
    .store {
      padding: 0;
      .row {
        padding: 0 15px;
        .store-info {
          padding: 0;
          .store-name {
            color: @brand-light-blue;
            margin: 0;
          }
          .store-address-line {
            margin: 0;
            line-height: 1.3;
          }
        }
        .store-map {
          padding: 0;
        }
      }
      .bottom-border-div {
        margin: 15px 0;
        padding: 0;
        hr {
          border: none;
          height: 2px;
          color: @brand-light-blue;
        }
      }
    }
  }
}

@media (min-width: @screen-ss) {
  #stores-location {
    h2{
      font-size: 34px;
    }
    .store-name {
      font-size: 18px;
    }
    .store-address-line {
      font-size: 18px;
    }
  }
}
