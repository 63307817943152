#contact-us-page {
  h1 {
    font-size: 34px;
    color: @brand-light-blue;
  }

  #contact-us-form {
    p {
      font-family: @font-name-myriad-cond-bold, @font-name-myriad-cond, @font-name-myriad-regular;
      color: @brand-light-blue;
      padding-top: 10px;
    }
    textarea {
      min-height: 100px;
    }
    #file-upload {
      padding-right: 15px;
      .fu1 {
        font-family: @font-name-myriad-cond-bold, @font-name-myriad-cond, @font-name-myriad-regular;
        color: @brand-light-blue;
        font-size: 16px;
      }
      .fu2 {
        font-family: @font-name-myriad-cond, @font-name-myriad-regular, @font-name-myriad-cond-bold;
        color: @brand-blue;
        font-size: 12px;
      }
      .btn-file {
        padding: 0 15px 10px 5px;
        img {
          margin-left: 5px;
        }
      }
      .text-small {
        padding-top: 0;
        padding-right: 15px;
        color: grey;
        font-size: 13px;
      }
    }
    #send-message {
      padding-right: 30px;
      button {
        border: none;
        font-size: 14px;
        font-family: @font-name-myriad-cond, @font-name-myriad-regular, @font-name-myriad-cond-bold;
        height: 30px;
        width: 80px;
      }
    }
  }

  #brand-info {
    span, p {
      color: @brand-blue;
      font-size: 16px;
    }
    .row {
      margin-top: 15px;
      margin-bottom: 25px;
    }
  }
}