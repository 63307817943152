.carousel {
  &.vertical {
    height: 530px;
    .carousel-inner {
      height: 500px;
      > .item {
        .transition(.3s ease-in-out bottom);

        // WebKit CSS3 transforms for supported devices
        @media all and (transform-3d), (-webkit-transform-3d) {
          .transition-transform(~'0.2s ease-in-out top');

          &.next,
          &.active.right {
            top: 500px;
          }

          &.prev,
          &.active.left {
            top: -500px;
          }

          &.next.left,
          &.prev.right,
          &.active {
            top: 0;
          }
        }
      }
    }
  }
}