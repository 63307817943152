@font-face {
  font-family: '@{font-name-thirsty}';
  src: url('@{font-path-thirsty}@{font-name-thirsty}.woff') format('woff');
}

@font-face {
  font-family: '@{font-name-myriad-regular}';
  src: url('@{font-path-myriad}@{font-name-myriad-regular}.woff') format('woff');
}

@font-face {
  font-family: '@{font-name-myriad-cond}';
  font-weight: bold;
  src: url('@{font-path-myriad}@{font-name-myriad-cond}.woff') format('woff');
}

@font-face {
  font-family: '@{font-name-myriad-cond-bold}';
  font-weight: bold;
  src: url('@{font-path-myriad}@{font-name-myriad-cond-bold}.woff') format('woff');
}

@font-face {
  font-family: '@{application-font-name}';
  src: url('@{application-font-path}@{application-font-name}.eot');
  src: url('@{application-font-path}@{application-font-name}.eot?#iefix') format('embedded-opentype'),
  url('@{application-font-path}@{application-font-name}.woff') format('woff'),
  url('@{application-font-path}@{application-font-name}.ttf') format('truetype'),
  url('@{application-font-path}@{application-font-name}.svg#@{application-font-name}') format('svg');
}

// Catchall baseclass
.pi {
  position: relative;
  top: 1px;
  display: inline-block;
  font: normal normal normal 16px/1 '@{application-font-name}';
  font-size: inherit;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* makes the font 33% larger relative to the icon container */
.pi-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.pi-2x {
  font-size: 2em;
}
.pi-3x {
  font-size: 3em;
}
.pi-4x {
  font-size: 4em;
}
.pi-5x {
  font-size: 5em;
}
.pi-8x {
  font-size: 8em;
}

.pi-clock:before {
  content: "\e602";
}
.pi-salad:before {
  content: "\e601";
}
.pi-facebook-footer:before {
  content: "\e600";
}
.pi-map-more:before {
  content: "\e613";
}
.pi-pdf-download:before {
  content: "\e612";
}
.pi-snack:before {
  content: "\e611";
}
.pi-map-marker:before {
  content: "\e610";
}
.pi-favourite:before {
  content: "\e619";
}
.pi-brunch:before {
  content: "\e61a";
}
.pi-burger:before {
  content: "\e61b";
}
.pi-classic:before {
  content: "\e61c";
}
.pi-download:before {
  content: "\e61d";
}
.pi-email:before {
  content: "\e61e";
}
.pi-facebook-header:before {
  content: "\e620";
}
.pi-grill:before {
  content: "\e622";
}
.pi-logo-footer:before {
  content: "\e623";
}
.pi-heart-circle:before {
  content: "\e625";
}
.pi-starter:before {
  content: "\e62d";
}
.pi-ice-cream:before {
  content: "\e62e";
}
.pi-infantil:before {
  content: "\e62f";
}
.pi-exclusive:before {
  content: "\e631";
}
.pi-euro:before {
  content: "\e632";
}
.pi-economico:before {
  content: "\e633";
}
.pi-gluten:before {
  content: "\e634";
}
.pi-milk:before {
  content: "\e635";
}
.pi-mostarda:before {
  content: "\e636";
}
.pi-egg:before {
  content: "\e637";
}
.pi-S02:before {
  content: "\e638";
}
.pi-sesamo:before {
  content: "\e639";
}
.pi-upload:before {
  content: "\e63c";
}
.pi-lupa:before {
  content: "\e63d";
}
.pi-heart:before {
  content: "\e63e";
}
.pi-facebook-share:before {
  content: "\e605";
}