.btn, a.btn {
  padding: 5px 20px 2px;
  font-family: @font-name-myriad-cond-bold;
  .fa {
    color: #fff;
    margin: 0 5px;
  }
  &.btn-transparent {
    .fa {
      color: @brand-grey-text;
    }
  }
  &.btn-black {
    .fa {
      color: @btn-black-color;
    }
  }
}

.btn {
  &.btn-sq {
    .button-size(2 + @padding-base-vertical; 2 * @padding-large-horizontal; @font-size-base; @line-height-base; @border-radius-base);
  }
  &.btn-file {
    position: relative;
    overflow: hidden;
    input[type=file] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      filter: alpha(opacity=0);
      opacity: 0;
      outline: none;
      background: white;
      cursor: inherit;
      display: block;
    }
  }
}

.form-control[readonly] {
  &.btn-file-readonly {
    background: transparent;
  }
}

// Red Button
.btn-red {
  .button-variant(@btn-red-color; @btn-red-bg; @btn-red-border);
}

.btn-light-blue {
  .button-variant(@btn-light-blue-color; @btn-light-blue-bg; @btn-light-blue-border);
}

.btn-blue {
  .button-variant(@btn-blue-color; @btn-blue-bg; @btn-blue-border);
}

.btn-dark-blue {
  .button-variant(@btn-dark-blue-color; @btn-dark-blue-bg; @btn-dark-blue-border);
}

.btn-white-text-blue {
  .button-variant(@btn-white-text-blue-color; @btn-white-text-blue-bg; @btn-white-text-blue-border);
}

.btn-pink {
  .button-variant(@btn-pink-color; @btn-pink-bg; @btn-pink-bg);
}

.btn-green {
  .button-variant(@btn-green-color; @btn-green-bg; @btn-green-border);
}

.btn-black {
  .button-variant(@btn-black-color; @btn-black-bg; @btn-black-border);
}

.btn-transparent {
  .button-variant(@brand-grey-text; transparent; @brand-grey-text);
}

.btn-transparent-white {
  .button-variant(@brand-white; transparent; @brand-white);
}

.btn-transparent-beach {
  .button-variant(@brand-beach; transparent; @brand-beach);
}

.btn-grey {
  .button-variant(@brand-grey-text; @btn-grey-bg; @btn-grey-border);
}

//Special case because is transparent
.btn-transparent {
  color: @brand-grey-text;
  background-color: transparent;
  border: @brand-grey-text 2px solid;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: @brand-grey-text;
    border-color: darken(@brand-grey-text, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: @brand-grey-text;
    }
  }
}

//Special case because is transparent
.btn-transparent-beach {
  color: @brand-beach;
  background-color: transparent;
  border: @brand-beach 2px solid;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: darken(@brand-beach, 12%);
    border-color: darken(@brand-beach, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: darken(@brand-beach, 12%);
    }
  }
}

//Special case because is transparent
.btn-transparent-white {
  color: @brand-white;
  background-color: transparent;
  border: @brand-white 2px solid;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    color: darken(@brand-white, 12%);
    border-color: darken(@brand-white, 12%);
  }
  &:active,
  &.active,
  .open > .dropdown-toggle& {
    background-image: none;
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    &,
    &:hover,
    &:focus,
    &.focus,
    &:active,
    &.active {
      border-color: darken(@brand-white, 12%);
    }
  }
}
