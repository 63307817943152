#about-us-page {
  #about-us-cms {
    .title {
      margin-bottom: 0;
    }
    .sub-title {
      margin-top: 0;
      margin-bottom: 25px;
      color: @brand-light-blue;
    }
  }

  #container-photo {
    padding: 0;
    .main-photo {
      padding: 0;
    }
  }

  .mission-and-vision {
    background-color: @brand-light-blue;
    padding-top: 50px;
    padding-bottom: 50px;
    h3 {
      font-size: 34px;
      color: @brand-white;
    }
    p {
      color: @brand-white;
      margin-top: 30px;
    }
  }

  .our-values {
    padding-top: 50px;
    padding-bottom: 50px;
    h3 {
      font-size: 34px;
      color: @brand-light-blue;
      margin-bottom: 45px;
    }
    .img-responsive {
      display: inline-block;
      padding: 0 15px;
    }
    @media (min-width: @screen-ss) {
      .img-left {
        padding: 0 30px 0 0;
        .img-responsive {
        }
      }
      .img-right {
        padding: 0 0 0 30px;
        .img-responsive {
        }
      }
    }
    p {
      font-family: @font-name-myriad-cond-bold, @font-name-myriad-cond, @font-name-myriad-regular;
      font-size: 20px;
      color: @brand-light-blue;
    }
  }
}

@media (min-width: @screen-md) {
  #about-us-page {
    .our-values {
      padding-left: 0;
      padding-right: 0;
      .img-responsive {
        padding: 0 40px;
      }
      .img-left {
        .img-responsive, p {
          padding: 0 80px 0 0;
        }
      }
      .img-right {
        .img-responsive, p {
          padding: 0 0 0 80px;
        }
      }
      p {
        margin-top: 25px;
        margin-bottom: 50px;
      }
    }
  }
}

@media (min-width: @screen-lg) {
  #about-us-page {
    .mission-and-vision {
      .container {
        width: 970px;
      }
    }
    #container-our-values {
      width: 970px;
    }
  }
}
