#new-review-page {
  .help-block {
    display: none;
  }
  #product-search-background {
    background-color: @brand-blue;
    padding: 15px;
    #product-search-form {
      .field {
        padding: 0;
        height: 36px;
        button {
          height: 36px;
          font-family: @font-family-bold-condensed;
          line-height: 2;
          border-style: solid;
        }
      }
      .select2-selection {
        font-family: @font-family-regular;
      }
    }
  }

  #product-photo {
    padding: 0;
    img {
      width: 100%;
    }
  }

  #product-info-background {
    background-color: @brand-blue;
    padding: 15px;
    #product-info {
      padding: 20px 0;
      h1, p {
        color: @brand-white;
        font-family: @font-family-bold-condensed;
      }
      h1 {
        font-size: 30px;
        margin: 0;
      }
      p {
        margin: 0;
      }
      .product-name-div {
        text-align: center;
        margin-bottom: 10px
      }
      .score-title {
        font-size: 20px;
      }
      .score-rating {
        font-size: 50px;
      }
      .score-votes {
        font-size: 15px;
      }
    }
  }

  #review {
    margin: 20px 0;
    h2 {
      font-size: 26px;
      color: @brand-light-blue;
      margin-bottom: 0;
      span {
        color: @brand-blue;
      }
    }
    h3 {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 50px;
      font-family: @font-family-bold-condensed;
      color: @brand-light-blue;
    }
    .rating {
      margin-bottom: 30px;
      padding: 0;
      p {
        color: @brand-grey-text;
      }
    }

    .rating-title {
      font-family: @font-family-bold-condensed;
      font-size: 20px;
    }

    .btn-score {
      padding: 0;
      &:focus {
        outline: none;
      }
      border: none;
      background-color: transparent;
      font-size: 30px;
      .fa-star, .fa-star-o {
        color: @brand-icon-blue;
      }
    }
    .buttons-div {
      padding: 0;
    }
    .field {
      .pd-15 {
        padding: 0 15px !important;
      }
    }
    textarea {
      resize: vertical;
    }
    #submit-button {
      font-family: @font-family-bold-condensed;
      border-style: solid;
      line-height: 2;
    }
  }
}

@media (min-width: @screen-ss) {
  #new-review-page {
    #product-photo {
      height: 180px;
    }
    #product-info-background {
      opacity: 0.8;
      #product-info {
        padding: 0;
        h1 {
          font-size: 30px;
        }
      }
    }
    #review {
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size: 26px;
      }
      .field {
        .pd-15 {
          padding: 0 !important;
        }
      }
    }
  }
}

@media (min-width: @screen-sm) {
  #new-review-page {
    #product-photo {
      height: 350px;
    }
    #product-info-background {
      #product-info {
        h1 {
          font-size: 34px;
          line-height: 3.5;
        }
        .product-name-div {
          margin: 0;
        }
      }
    }
    #review {
      h2 {
        font-size: 34px;
      }
    }
  }
}

@media (min-width: @screen-lg) {
  #new-review-page {
    #product-photo {
      height: 600px;
      overflow: hidden;
    }
    #product-info-background {
      position: relative;
      bottom: 150px;
    }
    #review {
      margin-top: -100px;
    }
  }
}
