/* ======= Pagination ======= */
.pagination {
  margin-bottom: 60px;
  background: none;
  li {
    a {
      padding: 6px 12px;
      border: none;
      color: @brand-grey-text;
      &:hover {
        color: darken(@brand-dark-blue, 25%);
      }
    }
  }
  .disabled {
    span {
      border: none;
      padding: 6px 12px;
    }
  }
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
  background-color: @brand-white;
  color: @brand-dark-blue;
  border: none;
}

.pagination > li > a:hover, .pagination > li > span:hover, .pagination > li > a:focus, .pagination > li > span:focus {
  background-color: lighten(@brand-dark-blue, 30%);
}
