/* ======= FAQ Section ======= */
.steps {
  color: @brand-white;

  .display-cell {
    p {
      color: @brand-white;
      min-height: 150px;
    }
  }
}
