#floating-social-buttons {
  z-index: 10;
  top: 40%;
  position: fixed;
  .social {
    margin: 5px 0;
  }
  .ig-badge {
    .ig-b- {
      display: inline-block;
    }
    .ig-b- img {
      visibility: hidden;
    }
    .ig-b-:hover {
      background-position: 0 -60px;
    }
    .ig-b-:active {
      background-position: 0 -120px;
    }
    .ig-b-48 {
      width: 48px;
      height: 48px;
      background: url("//prod-acascata-public.s3-eu-west-1.amazonaws.com/assets/bower/images/social/instagram-glyph.png") no-repeat 0 0;
    }
    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
      .ig-b-48 {
        background-image: url("//prod-acascata-public.s3-eu-west-1.amazonaws.com/assets/bower/images/social/instagram-glyph.png");
        background-size: 60px 178px;
      }
    }
  }
}
